import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";

function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <button
      className="btn btn-danger"
      onClick={handleLogout}
      style={{ float: "right", margin: "10px" }}
    >
      Logout
    </button>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard/*"
          element={
            <div>
              <LogoutButton />
              <Dashboard />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
