import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import ProjectTab from "./ProjectTab";
import JobOpeningsTab from "./JobOpeningsTab";
import TeamMembersTab from "./TeamMembersTab";
import backgroundImage from "../Images/footer-bg.webp";

function Dashboard() {
  return (
    <div
      className="dashcontainer"
      style={{
        backgroundImage: `url(${backgroundImage})`,

        backgroundColor: "#0c2d62",
      }}
    >
      <div
        className="container"
        style={{
          backgroundColor: "ghostwhite",
          padding: "50px",
        }}
      >
        <h2 className="mb-4">Dashboard</h2>
        <Tabs defaultActiveKey="projects" id="dashboard-tabs" className="mb-3">
          <Tab eventKey="projects" title="Projects">
            <ProjectTab />
          </Tab>
          <Tab eventKey="jobOpenings" title="Job Openings">
            <JobOpeningsTab />
          </Tab>
          <Tab eventKey="teamMembers" title="Team Members">
            <TeamMembersTab />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Dashboard;
