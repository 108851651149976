import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const TeamMembersTab = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [editedId, setEditedId] = useState("");
  const [editedName, setEditedName] = useState("");
  const [editedPosition, setEditedPosition] = useState("");
  const [newId, setNewId] = useState("");
  const [newName, setNewName] = useState("");
  const [newPosition, setNewPosition] = useState("");

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = () => {
    axios
      .get("http://162.241.217.210:3001/TeamMembers")
      .then((response) => setTeamMembers(response.data))
      .catch((error) => {
        console.error("Error fetching team members:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch team members.",
        });
      });
  };

  const handleEdit = (id) => {
    axios
      .get(`http://162.241.217.210:3001/TeamMembers/${id}`)
      .then((response) => {
        setSelectedMember(response.data);
        setEditedId(response.data.id);
        setEditedName(response.data.name);
        setEditedPosition(response.data.position);
        setShowModal(true);
      })
      .catch((error) => {
        console.error("Error fetching team member:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch team member details.",
        });
      });
  };

  const handleSaveChanges = () => {
    const data = {
      id: editedId,
      name: editedName,
      position: editedPosition,
    };
    axios
      .put(`http://162.241.217.210:3001/TeamMembers/${selectedMember.id}`, data)
      .then(() => {
        if (selectedMember.id !== editedId) {
          axios
            .delete(
              `http://162.241.217.210:3001/TeamMembers/${selectedMember.id}`
            )
            .then(() => {
              axios
                .post("http://162.241.217.210:3001/TeamMembers", data)
                .then(() => {
                  setShowModal(false);
                  Swal.fire("Success!", "Changes were saved.", "success");
                  fetchTeamMembers();
                })
                .catch((error) =>
                  console.error("Error adding new team member:", error)
                );
            })
            .catch((error) =>
              console.error("Error deleting old team member:", error)
            );
        } else {
          setShowModal(false);
          Swal.fire("Success!", "Changes were saved.", "success");
          fetchTeamMembers();
        }
      })
      .catch((error) => console.error("Error updating team member:", error));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`http://162.241.217.210:3001/TeamMembers/${id}`)
          .then(() => {
            fetchTeamMembers();
            Swal.fire("Deleted!", "Team member has been deleted.", "success");
          })
          .catch((error) =>
            console.error("Error deleting team member:", error)
          );
      }
    });
  };

  const handleAddNewMember = () => {
    setNewId("");
    setNewName("");
    setNewPosition("");
    setShowModal(true);
  };

  const handleSaveNewMember = () => {
    const data = {
      id: newId,
      name: newName,
      position: newPosition,
    };

    axios
      .post("http://162.241.217.210:3001/TeamMembers", data)
      .then(() => {
        setShowModal(false);
        Swal.fire("Success", "New team member added successfully.", "success");
        fetchTeamMembers();
      })
      .catch((error) => console.error("Error adding new job opening:", error));
  };

  return (
    <div>
      <h3>Team Members</h3>

      <Button variant="success" onClick={handleAddNewMember}>
        Add New Team Member
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Position</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {teamMembers.map((member) => (
            <tr key={member.id}>
              <td>{member.id}</td>
              <td>{member.name}</td>
              <td>{member.position}</td>
              <td>
                <Button variant="info" onClick={() => handleEdit(member.id)}>
                  Edit
                </Button>{" "}
                <Button
                  variant="danger"
                  onClick={() => handleDelete(member.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedMember.id ? "Edit" : "Add New"} Team Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formMemberId">
              <Form.Label>Member ID</Form.Label>
              <Form.Control
                type="text"
                value={selectedMember.id ? editedId : newId}
                onChange={(e) =>
                  selectedMember.id
                    ? setEditedId(e.target.value)
                    : setNewId(e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={selectedMember.id ? editedName : newName}
                onChange={(e) =>
                  selectedMember.id
                    ? setEditedName(e.target.value)
                    : setNewName(e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId="formPosition">
              <Form.Label>Position</Form.Label>
              <Form.Control
                type="text"
                value={selectedMember.id ? editedPosition : newPosition}
                onChange={(e) =>
                  selectedMember.id
                    ? setEditedPosition(e.target.value)
                    : setNewPosition(e.target.value)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={
              selectedMember.id ? handleSaveChanges : handleSaveNewMember
            }
          >
            {selectedMember.id ? "Save Changes" : "Save New Team Member"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TeamMembersTab;
