import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const JobOpeningsTab = () => {
  const [jobOpenings, setJobOpenings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [editedId, setEditedId] = useState("");
  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedIsActive, setEditedIsActive] = useState(false);
  const [newId, setNewId] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newIsActive, setNewIsActive] = useState(false);

  useEffect(() => {
    fetchJobOpenings();
  }, []);

  const fetchJobOpenings = () => {
    axios
      .get("http://162.241.217.210:3001/JobOpenings")
      .then((response) => setJobOpenings(response.data))
      .catch((error) => {
        console.error("Error fetching job openings:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch job openings.",
        });
      });
  };

  const handleEdit = (id) => {
    axios
      .get(`http://162.241.217.210:3001/JobOpenings/${id}`)
      .then((response) => {
        setSelectedJob(response.data);
        setEditedId(response.data.id);
        setEditedTitle(response.data.title);
        setEditedDescription(response.data.description);
        setEditedIsActive(response.data.is_active);
        setShowModal(true);
      })
      .catch((error) => {
        console.error("Error fetching job opening:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch job opening details.",
        });
      });
  };

  const handleSaveChanges = () => {
    const data = {
      id: editedId,
      title: editedTitle,
      description: editedDescription,
      is_active: editedIsActive,
    };

    axios
      .put(`http://162.241.217.210:3001/JobOpenings/${selectedJob.id}`, data)
      .then(() => {
        if (selectedJob.id !== editedId) {
          axios
            .delete(`http://162.241.217.210:3001/JobOpenings/${selectedJob.id}`)
            .then(() => {
              axios
                .post("http://162.241.217.210:3001/JobOpenings", data)
                .then(() => {
                  setShowModal(false);
                  Swal.fire("Success!", "Changes were saved.", "success");
                  fetchJobOpenings();
                })
                .catch((error) =>
                  console.error("Error adding new job opening:", error)
                );
            })
            .catch((error) =>
              console.error("Error deleting old job opening:", error)
            );
        } else {
          setShowModal(false);
          Swal.fire("Success!", "Changes were saved.", "success");
          fetchJobOpenings();
        }
      })
      .catch((error) => console.error("Error updating job opening:", error));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`http://162.241.217.210:3001/JobOpenings/${id}`)
          .then(() => {
            fetchJobOpenings();
            Swal.fire("Deleted!", "Job opening has been deleted.", "success");
          })
          .catch((error) =>
            console.error("Error deleting job opening:", error)
          );
      }
    });
  };

  const handleAddNewJob = () => {
    setNewId("");
    setNewTitle("");
    setNewDescription("");
    setNewIsActive(false);
    setShowModal(true);
  };

  const handleSaveNewJob = () => {
    const data = {
      id: newId,
      title: newTitle,
      description: newDescription,
      is_active: newIsActive,
    };

    axios
      .post("http://162.241.217.210:3001/JobOpenings", data)
      .then(() => {
        setShowModal(false);
        Swal.fire("Success!", "New job opening added successfully.", "success");
        fetchJobOpenings();
      })
      .catch((error) => console.error("Error adding new job opening:", error));
  };

  return (
    <div>
      <h3>Job Openings</h3>

      <Button variant="success" onClick={handleAddNewJob}>
        Add New Job Opening
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Description</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobOpenings.map((job) => (
            <tr key={job.id}>
              <td>{job.id}</td>
              <td>{job.title}</td>
              <td>{job.description}</td>
              <td>{job.is_active ? "Yes" : "No"}</td>
              <td>
                <Button variant="info" onClick={() => handleEdit(job.id)}>
                  Edit
                </Button>{" "}
                <Button variant="danger" onClick={() => handleDelete(job.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedJob.id ? "Edit" : "Add New"} Job Opening
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formJobId">
              <Form.Label>Job ID</Form.Label>
              <Form.Control
                type="text"
                value={selectedJob.id ? editedId : newId}
                onChange={(e) =>
                  selectedJob.id
                    ? setEditedId(e.target.value)
                    : setNewId(e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={selectedJob.id ? editedTitle : newTitle}
                onChange={(e) =>
                  selectedJob.id
                    ? setEditedTitle(e.target.value)
                    : setNewTitle(e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={selectedJob.id ? editedDescription : newDescription}
                onChange={(e) =>
                  selectedJob.id
                    ? setEditedDescription(e.target.value)
                    : setNewDescription(e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId="formIsActive">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={selectedJob.id ? editedIsActive : newIsActive}
                onChange={(e) =>
                  selectedJob.id
                    ? setEditedIsActive(e.target.checked)
                    : setNewIsActive(e.target.checked)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={selectedJob.id ? handleSaveChanges : handleSaveNewJob}
          >
            {selectedJob.id ? "Save Changes" : "Save New Job Opening"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JobOpeningsTab;
