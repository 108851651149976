import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button } from "react-bootstrap";

const ProjectsTab = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios
      .get("http://162.241.217.210:3001/projects")
      .then((response) => setProjects(response.data))
      .catch((error) => console.error("Error fetching projects:", error));
  }, []);

  const handleEdit = (id) => {
    // Add edit logic here
    console.log(`Editing Project with ID: ${id}`);
  };

  const handleDelete = (id) => {
    // Add delete logic here
    console.log(`Deleting Project with ID: ${id}`);
  };

  return (
    <div>
      <h3>Projects</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Link</th>
            <th>Categories</th>
            <th>Location</th>
            <th>Started</th>
            <th>Ended</th>
            <th>Cover</th>
            <th>Featured</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <tr key={project.id}>
              <td>{project.id}</td>
              <td>{project.title}</td>
              <td>{project.link}</td>
              <td>{project.categories}</td>
              <td>{project.location}</td>
              <td>{project.started}</td>
              <td>{project.ended}</td>
              <td>{project.cover}</td>
              <td>{project.is_active ? "Yes" : "No"}</td>
              <td>
                <Button variant="info" onClick={() => handleEdit(project.id)}>
                  Edit
                </Button>{" "}
                <Button
                  variant="danger"
                  onClick={() => handleDelete(project.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProjectsTab;
